
<template xmlns:src="http://www.w3.org/1999/xhtml" xmlns:v-bind="http://www.w3.org/1999/xhtml"
          xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="col s12 no-padding">
        <div class="col s12">
            <div class="col s12 font-bold font-16 vert-offset-top-1 padding-bottom-1 grey-border border-bottom-1 border-lighten-2">
                <div class="col s6">
                    Your FoodCredit Statement
                </div>
                <div class="col s6 right-align"><img width="50" height="50" src="brandbox_logo.png" alt="Logo"></div>
            </div>
            <div class="col s12 padding-top-1">
                <div class="col s12 m6 l6 vert-offset-bottom-1">
                    <div class="col s12">
                        Kenta Cunta
                    </div>
                    <div class="col s12">
                        71000001
                    </div>
                </div>
                <div class="col s12 m6 l6 right-align vert-offset-bottom-1">
                    <div class="col s12">
                        FoodCredit
                    </div>
                    <div class="col s12">
                        Setlhoa Office Park
                    </div>
                    <div class="col s12">
                        Building 7 Unit 3
                    </div>
                    <div class="col s12">
                        3930322
                    </div>
                </div>

            </div>


            <div class="col s12 vert-offset-bottom-1 ">
                <div class="col s12 no-padding font-bold border-bottom-1">
                    As of {{opening_date}}
                </div>

                <div class="col s12 no-padding">

                    <div class="col s12 m4 padding-top-half">
                        <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                            <div class="col s12 no-padding">
                                <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                    <span>Invoice &#35;</span>
                                </div>
                                <div class="col s8 m6 font-bold text-align-right font-12">
                                    <span>{{invoice.number}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m4 padding-top-half">
                        <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                            <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                <span>Available to spend</span>

                            </div>
                            <div class="col s8 m6 font-bold text-align-right font-12">

                                {{account.credit}} <span>{{country.currency.code}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col s12 m4 padding-top-half">
                        <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                            <div class="col s12 no-padding">
                                <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                    <span>Monthly Rate</span>
                                </div>
                                <div class="col s8 m6 font-bold text-align-right font-12">
                                    <span>10%</span>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


            <div class="col s12 vert-offset-bottom-1 ">

                <table class="">
                    <thead>
                    <tr>
                        <td>Item</td>
                        <td class="right-align font-bold">Amount (BWP)</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Opening Balance (B/F)</td>
                        <td class="right-align font-bold">{{invoice.opening_balance}}</td>
                    </tr>
                    <tr>
                        <td>Purchases</td>
                        <td class="right-align font-bold">{{invoice.opening_balance}}</td>
                    </tr>
                    <tr>
                        <td>Payments</td>
                        <td class="right-align font-bold">{{invoice.summary.recharge}}</td>
                    </tr>
                    <tr>
                        <td>Penalties</td>
                        <td class="right-align font-bold">{{ penalty_charged}}</td>
                    </tr>

                    <tr>
                        <td>Interest</td>
                        <td class="right-align font-bold">{{ invoice.summary.interest}}</td>
                    </tr>

                    <tr>
                        <td>Total Owed</td>
                        <td class="right-align font-bold">{{invoice.amount}}</td>
                    </tr>
                    </tbody>
                </table>


            </div>


            <div class="col s12 vert-offset-top-1 vert-offset-bottom-1">

                <div class="col s12 m6 padding-top-half">
                    <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                        <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                            <span>Minimum Payment</span>

                        </div>
                        <div class="col s8 m6 font-bold text-align-right font-12">

                            {{invoice.min_payment}} <span>{{country.currency.code}}</span>
                        </div>
                    </div>
                </div>

                <div class="col s12 m6 padding-top-half">
                    <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                        <div class="col s12 no-padding">
                            <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                <span>Payment Due Date</span>
                            </div>
                            <div class="col s8 m6 font-bold text-align-right font-12">
                                <span>{{min_payment_date}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <!--<div class="col s12 padding-top-1" v-if="hasTransactions">
                <table class="font-10">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Amount ({{country.currency.code}})</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(_item, _index) in transactions" :key="_index">
                        <td>{{ _index + 1}}</td>
                        <td>{{ _item.amount }}</td>
                        <td>{{ _item.date }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>-->
        </div>
        <div
                class="col s12 vert-offset-top-half padding-top-1 grey-border border-bottom-1 border-lighten-2 center">
            <button
                    v-if="hasInvoice"
                    class="btn btn-tiny gold-text black margin-right-1"
                    @click.prevent="getInvoice()"
            >Refresh</button>

            <button
                    v-if="hasInvoice"
                    class="btn btn-tiny gold-text black"
                    @click.prevent="downloadInvoice()"
            >Download</button>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import {mapState} from "vuex";
    import M from "materialize-css";
    import moment from 'moment';


    export default {
        name: "CustomerInvoiceViewComponent",
        components:{
        },
        data() {
            return {
                triggerTooltip:false,
            }
        },
        created () {
        },
        mounted() {
        },
        methods : {
            getInvoice:function()
            {
                this.$store.dispatch("FC_GET_CUSTOMER_INVOICE", {
                    invoice:this.invoice.ref,
                }).then(() => {
                    this.$forceUpdate();
                });
            },
            downloadInvoice:function()
            {
                this.$store.dispatch("START_PROCESSING");
                return new Promise(resolve => {
                    http({
                        url: "/download/statement",
                        data: {
                            invoice: this.invoice.ref
                        },
                        responseType: "blob",
                        method: "POST",
                        timeout: 30000
                    })
                        .then(resp => {
                            this.$store.dispatch("STOP_PROCESSING");

                            const blob = new Blob([resp.data], {
                                type: "application/octet-stream"
                            });
                            const link = document.createElement("a");
                            link.href = window.URL.createObjectURL(blob);
                            link.target = "_blank";
                            link.setAttribute("download", "statement.pdf");
                            document.body.appendChild(link);
                            link.click();
                            //URL.revokeObjectURL(link.href);
                            //console.log("RESP-"+JSON.stringify(resp.headers['content-type: ']));

                            resolve(resp);
                        })
                        .catch(response => {
                            console.log("Error: " + JSON.stringify(response.data));
                            this.$store.dispatch("STOP_PROCESSING");
                            this.$store.dispatch("GET_MESSAGES", response.data.messages);
                        });
                });
            },

        },
        computed: {
            ...mapState({
                customer:state=>state.foodcredit.customer,
                account:state=>state.foodcredit.account,
                activated:state=>state.foodcredit.active,
                confirmed:state=>state.foodcredit.confirmed,
                selected_service:state=>state.customers.customer_selected_service,
                msisdn: state => state.customers.msisdn,
                processing: state => state.processing,
                country: state => state.customers.country,
                invoices: state => state.foodcredit.paginated_list_invoices,
                invoice: state => state.foodcredit.invoice,
                transactions:state => state.foodcredit.invoice.transactions,
            }),

            min_payment_date:function () {
                if(this.hasInvoice)
                {
                    return moment(this.invoice.due_date, 'DD-MM-YYYY').format("DD MMMM YYYY");
                }
            },
            penalty_charged:function () {

                if(typeof this.invoice.penalty !== "undefined" && this.invoice.penalty !== null && typeof this.invoice.penalty.data !== "undefined" && this.invoice.penalty.data !== null )
                {
                    return this.invoice.penalty.data.amount;
                }
                else
                {
                    return 0;
                }

            },
            opening_date:function () {
                if(this.hasInvoice)
                {
                    return moment(this.invoice.opening_date, 'DD-MM-YYYY').format("DD MMMM YYYY");
                }
            },
            isProcessing: function()
            {
                return (typeof this.processing !== "undefined" && this.processing !== null && this.processing > 0);
            },
            hasCustomer: function()
            {
                return (typeof this.customer !== "undefined" && this.customer !== null);
            },
            hasAccount: function()
            {
                return (this.hasCustomer && typeof this.account !== "undefined" && this.account !== null);
            },
            hasTransactions: function()
            {
                return (this.hasInvoice && typeof this.transactions !== "undefined" && this.transactions !== null && this.transactions.length > 0);
            },
            hasInvoice: function()
            {
                return (this.hasAccount && typeof this.invoice !== "undefined" && this.invoice !== null);
            },
            isConfirmed: function()
            {
                return (this.hasCustomer && typeof this.confirmed !== "undefined" && this.confirmed !== null && this.confirmed === true);
            },
            isActivated: function()
            {
                return (this.hasCustomer && typeof this.activated !== "undefined" && this.activated !== null && this.activated === true);
            },
            mustRefreshCustomer: function()
            {
                return (!this.isProcessing && !this.hasCustomer);
            },
            current_page: function()
            {
                if(!this.hasTransactions)
                {
                    return null;
                }
                if(typeof this.pagination === "undefined" || this.pagination === null)
                {
                    return null;
                }
                if(typeof this.pagination.current_page === "undefined" || this.pagination.current_page === null)
                {
                    return null;
                }
                return this.pagination.current_page;
            },
            total_pages: function()
            {
                if(!this.hasInvoices)
                {
                    return null;
                }
                if(typeof this.pagination === "undefined" || this.pagination === null)
                {
                    return null;
                }
                if(typeof this.pagination.total_pages === "undefined" || this.pagination.total_pages === null)
                {
                    return null;
                }
                return this.pagination.total_pages;
            },
            next_page: function()
            {
                if(typeof this.current_page === "undefined" || this.current_page === null)
                    return null;

                if(typeof this.total_pages === "undefined" || this.total_pages === null)
                    return null;

                if(this.total_pages <= this.current_page)
                    return null;

                return (this.current_page+1);
            },
            showNext:function ()
            {
                return (typeof this.next_page !== "undefined" && this.next_page !== null);
            },
        },


        watch: {
        }
    };
</script>