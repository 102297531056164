
<template xmlns:src="http://www.w3.org/1999/xhtml" xmlns:v-bind="http://www.w3.org/1999/xhtml"
          xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">

            <customer-invoice-view-component></customer-invoice-view-component>
        </div>

    </div>
</template>
<script>
    // @ is an alias to /src
    import {mapState} from "vuex";
    import CustomerInvoiceViewComponent from "@/components/customers/services/food-credit/customerInvoiceView.vue";


    export default {
        name: "CustomerInvoiceInfo",
        components:{
            CustomerInvoiceViewComponent
        },
        data() {
            return {
                activeClass: "material-icons green-text text-darken-2 left tiny",
                inactiveClass: "material-icons green-text text-darken-2 left tiny",
            }
        },
        created () {
            this.loadPage();


        },
        computed: {
            ...mapState({
                services_list:state=>state.services.services,
                l2l_alias:state=>state.services.service_alias_loyal_to_local,
                fc_alias:state=>state.services.service_alias_food_credit,
                selected_service:state=>state.customers.customer_selected_service,

            }),

            hasSelectedService: function()
            {
                return(typeof this.selected_service !== "undefined" && this.selected_service !== null && typeof this.selected_service.alias !== "undefined" && this.selected_service.alias !== null);
            },
            hasL2LAlias: function()
            {
                return(typeof this.l2l_alias !== "undefined" && this.l2l_alias !== null && this.l2l_alias.length > 0);
            },
            hasFCAlias: function()
            {
                return(typeof this.fc_alias !== "undefined" && this.fc_alias !== null && this.fc_alias.length > 0);
            },
            isLoyalToLocal: function()
            {
                return (this.hasSelectedService && this.hasL2LAlias && this.selected_service.alias === this.l2l_alias);
            },
            isFoodCredit: function()
            {
                return (this.hasSelectedService && this.hasFCAlias && this.selected_service.alias === this.fc_alias);
            },
        },
        methods : {
            loadPage: function()
            {
                let _header = null;
                if(this.hasSelectedService)
                {
                    _header = {
                        title: this.selected_service.name,
                        icon: "chevron_left",
                        url: "/view/service"
                    };
                }
                this.$setPageHeader(_header);
                //this.loadMenu();
            },
            loadMenu: function()
            {
                this.$store.dispatch('COMMIT_SET_MENU_LINKS', [
                    {
                        title : "Find Supplier",
                        link: "/search/supplier",
                        icon: "search"
                    }
                ]);
            },
        },

        watch: {
        }
    };
</script>